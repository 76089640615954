import {
  ConfirmationBox,
  DatePickerWithInput,
  HeadingGroup,
  ModalLayout,
  NavGroup,
  Pagination,
  // SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useBillEstimate from "./useBillEstimate";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import InvoicePdf from "./InvoicePdf";
import Style from "../staffBilling.module.scss";
import EstimateReport from "./EstimatePdf";
import EstimateReReport from "../Invoice/Print/EstimateRePdf";
import { Link } from "react-router-dom";
// import Style from "./billEstimate.module.scss";

const BillEstimate = () => {
  const {
    queryList,
    isFetching,
    // isLoading,
    tableFields,
    showEditModal,
    estimateReRef,
    queryState,
    invoiceRef,
    estimateRef,
    paginationOptions,
    date,
    handleDateChange,
    handleAssign,
    handleSort,
    handleSearch,
    handlePagination,
    handlePrintClick,
    handlePageSize,
    refetch,
    getRow,
    handleEditColumnsClick,
    updateBillEstimateFields,
    handleRegenerate,
    tabNavs,
    activeTab,
    handleCNFModal,
    handleCancelCNFModal,
  } = useBillEstimate();

  return (
    <div>
      <HeadingGroup title={"Bill Estimate"} className={`pro-mb-4`} />
      <div className="col-auto pro-pt-3 pro-pb-6">
        {/* <SearchFilters
          onSearchInput={handleSearch}
          showActions={true}
          handleActionClick={handleEditColumnsClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
        /> */}
        <div className={`${Style.main_container}`}>
          <NavGroup navigations={tabNavs} type={"type-2"} Link={Link} />
        </div>
        <div className="col-auto pro-pt-3 ">
          <div className="row gx-2 gy-2 flex-fill">
            <div className={`col-auto`}>
              <div className="input-wrap">
                <input
                  type="text"
                  onChange={handleSearch}
                  placeholder="Search this table"
                  className={`pro-input icon-r lg ${Style.search_table}`}
                />
                <span className="pro-icon">
                  {<span className="material-symbols-outlined">search</span>}
                </span>
              </div>
            </div>
            <div className={`col-auto`}>
              <button
                className="pro-btn pro-btn-outline lg"
                onClick={handleEditColumnsClick}
              >
                Edit Columns
              </button>
            </div>
            <div className={`col-auto`}>
              <DatePickerWithInput
                CalendarOutlineIcon={
                  <span className="material-symbols-outlined">
                    calendar_month
                  </span>
                }
                range={true}
                initialValue={date}
                onDateChange={handleDateChange}
              ></DatePickerWithInput>
            </div>
          </div>
        </div>
        <Table
          multiSelect={true}
          data={queryList?.data?.data || []}
          uniqueID={"id"}
          fields={tableFields}
          SortIcon={<FaSort />}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={queryState?.currentPageSize}
          assignable={
            activeTab === "completed"
              ? false
              : queryList?.data?.generate_invoice_permission
              ? true
              : false
          }
          handleAssign={handleAssign}
          assignText={"Generate Invoice"}
          assignIcon={
            <span className="material-symbols-outlined">receipt_long</span>
          }
          editIcon={<span class="material-symbols-outlined">print</span>}
          editText={"Print Estimate"}
          editable={true}
          handleEdit={handlePrintClick}
          deletable={
            activeTab === "completed"
              ? false
              : queryList?.data?.regenerate_permission
              ? true
              : false
          }
          deleteText={"Regenerate"}
          deleteIcon={
            <span class="material-symbols-outlined">receipt_long</span>
          }
          handleDelete={handleRegenerate}
          showCheckBox={true}
          clear={queryState?.clear}
        />
        {queryList?.data?.data?.length > 0 && (
          <Pagination
            currentPage={queryState?.currentPage}
            totalPageCount={Math.ceil(
              queryList.data.total_count / queryState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={queryList?.data?.fields}
              moduleId={queryList?.data?.module_id}
              updateData={updateBillEstimateFields}
            />
          </div>
        </ModalLayout>
        <div className={Style.pdf_container}>
          <InvoicePdf invoiceRef={invoiceRef} />
          <EstimateReport estimateRef={estimateRef} />
        </div>
        <div className={Style.pdf_container}>
          <EstimateReReport estimateRef={estimateReRef} />
        </div>
      </div>
      <ModalLayout
        show={queryState?.warningModal}
        handleClose={handleCNFModal}
        backdrop="static"
      >
        <div className="pro-m-5">
          <ConfirmationBox
            title={"Your Monthly bill may include daily bills also "}
            subTitle={"Do you want to continue ?"}
            submitText={"Yes"}
            submitAction={handleCNFModal}
            isRight={true}
            cancelText={"Cancel"}
            cancelAction={handleCancelCNFModal}
          />
        </div>
      </ModalLayout>
    </div>
  );
};

export default BillEstimate;
