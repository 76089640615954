import {
  Button,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import usePrintingTable from "./usePrintingTable";
import ShowForm from "../ShowForm";
import BulkPass from "../BulkPass";

const PrintingTable = ({ gliNumber }) => {
  const {
    state,
    tableData,
    tableFields,
    isLoading,
    isFetching,
    paginationOptions,
    currentPageSize,
    getRow,
    handleBulkPass,
    handleSearch,
    handlePageSize,
    handleEditClick,
    handlePagination,
    handlePrintClick,
  } = usePrintingTable();

  return (
    <div>
      <h6 className="pro-ttl h6">{gliNumber}</h6>
      {!state?.isEdit && (
        <div className="pro-d-flex">
        <SearchFilters
          onSearchInput={handleSearch}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters={false}
          searchInputProps={{ value: state?.search }}
          showActions={false}
          loading={isLoading}
        />
        <Button
          className={"pro-btn-primary"}
          type="button"
          onClick={handleBulkPass}
        >
          Bulk Pass
        </Button>
      </div>
      )}
      
      {state?.showForm ? (
        <ShowForm />
      ) : (
        <Table
          multiSelect={true}
          data={tableData?.data?.data ?? []}
          uniqueID={"_id"}
          fields={tableFields}
          SortIcon={<FaSort />}
          assignIcon={<span className="material-symbols-outlined">print</span>}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          getRow={getRow}
          loading={isFetching}
          perpage={currentPageSize}
          assignable={tableData?.data?.printing_permission}
          deletable={false}
          showCheckBox={true}
          editable={true}
          handleEdit={handleEditClick}
          handleAssign={handlePrintClick}
          assignText={`Print`}
          clear={state.clear}
        />
      )}
      {tableData?.data?.data && !state?.showForm && (
        <Pagination
          currentPage={state?.currentReissuePage}
          totalPageCount={Math.ceil(
            tableData?.data?.total_count / tableData?.data?.per_page
          )}
          onPageChange={handlePagination}
          options={paginationOptions}
          onActionChange={handlePageSize}
          center
        />
      )}
      <ModalLayout
        title={"Bulk Pass"}
        show={state.bulkPassModal}
        handleClose={handleBulkPass}
        closeIcon={<span className="material-symbols-outlined">close</span>}
      >
        <BulkPass />
      </ModalLayout>
    </div>
  );
};

export default PrintingTable;
