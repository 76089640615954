import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useReassignModel from "./useReassignModel";

const ReassignModel = ({ refetch, activeTab }) => {
  const {
    formik,
    processObj,
    processData,
    getFieldError,
    handleCancel,
    getCommitList,
    committedList,
    committedObj,
    setCertificateList,
    certificateList,
    userList,
    userObj,
    handleItemChange,
  } = useReassignModel({
    refetch,
    activeTab,
  });
  return (
    <div className="pro-p-5">
      <div>
        <div className="input-wrap pro-mb-4">
          <label
            htmlFor="process_id"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Process List
          </label>
          <Select
            id="process_id"
            name="process_id"
            placeholder={"Select"}
            className={`pro-input lg  ${
              getFieldError("process_id") && " error"
            }`}
            classNamePrefix="pro-input"
            options={processData}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?._id}
            value={processObj}
            onBlur={formik?.handleBlur("process_id")}
            onChange={(value) => {
              formik?.setFieldValue("process_id", value?._id || "");
              formik?.setFieldValue("process_slug", value?.slug || "");
              getCommitList(value);
            }}
            menuPlacement="auto"
            isClearable
          />
          {getFieldError("process_id") && (
            <span className="error-text">{getFieldError("process_id")}</span>
          )}
        </div>
        <div className="input-wrap pro-mb-4">
          <label
            htmlFor="committed_id"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Process Committed
          </label>
          <Select
            id="committed_id"
            name="committed_id"
            placeholder={"Select"}
            className={`pro-input lg  ${
              getFieldError("committed_id") && " error"
            }`}
            classNamePrefix="pro-input"
            options={committedList}
            getOptionLabel={(option) => option?.user_name}
            getOptionValue={(option) => option?.user_id}
            value={committedObj}
            onBlur={formik?.handleBlur("committed_id")}
            onChange={(value) => {
              formik?.setFieldValue("committed_id", value?.user_id || null);
              setCertificateList(value?.item_ids);
            }}
            menuPlacement="auto"
            isClearable
            isDisabled={committedList?.length > 0 ? false : true}
          />
          {getFieldError("committed_id") && (
            <span className="error-text">{getFieldError("committed_id")}</span>
          )}
        </div>
        <div className="input-wrap pro-mb-4">
          <label
            htmlFor="item_details_id"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Certificates
          </label>
          <Select
            id="item_details_id"
            name="item_details_id"
            placeholder={"Select"}
            className={`pro-input lg  ${
              getFieldError("item_details_id") && " error"
            }`}
            classNamePrefix="pro-input"
            options={certificateList}
            getOptionLabel={(option) => option?.certificate_number}
            getOptionValue={(option) => option?.item_id}
            value={certificateList?.filter((m) =>
              formik.values.item_details_id?.includes?.(m?.item_id)
            )}
            onBlur={formik?.handleBlur("item_details_id")}
            onChange={(value) => handleItemChange(value)}
            menuPlacement="auto"
            isClearable
            isDisabled={certificateList?.length > 0 ? false : true}
            isMulti
          />
          {getFieldError("item_details_id") && (
            <span className="error-text">
              {getFieldError("item_details_id")}
            </span>
          )}
        </div>

        <div className="input-wrap pro-mb-4">
          <label
            htmlFor="employ_id"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Reassign To
          </label>
          <Select
            id="employ_id"
            name="employ_id"
            placeholder={"Select"}
            className={`pro-input lg  ${
              getFieldError("employ_id") && " error"
            }`}
            classNamePrefix="pro-input"
            options={userList}
            getOptionLabel={(option) => option?.staff_name}
            getOptionValue={(option) => option?.id}
            value={userObj}
            onBlur={formik?.handleBlur("employ_id")}
            onChange={(value) => {
              formik?.setFieldValue("employ_id", value?.id || null);
            }}
            menuPlacement="auto"
            isClearable
          />
          {getFieldError("employ_id") && (
            <span className="error-text">{getFieldError("employ_id")}</span>
          )}
        </div>
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik?.handleSubmit}
        >
          Reassign
        </Button>
      </div>
    </div>
  );
};

export default ReassignModel;
