import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/Orders/orderSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateOrderData } from "../../../store/slices/Orders/addOrderSlice";
import { useSelector } from "react-redux";
import {
  useGetOrdersListDataQuery,
  useUpdateOrderTableHeadDataMutation,
} from "../../../store/queries/Orders";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { deleteOrder, getOrderData, getPrintData } from "./api";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { subDays } from "date-fns";

const useOrder = () => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];
  const dispatch = useDispatch();
  const [updateOrderFields] = useUpdateOrderTableHeadDataMutation();
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  // eslint-disable-next-line no-unused-vars
  const [date, setDate] = useState({
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
    key: "selection",
  });
  const orderState = useSelector((state) => state.order);

  const stockPrintRef = useRef(null);

  const {
    data: ordersList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetOrdersListDataQuery({
    per_page: orderState.currentPageSize,
    page: orderState.currentPage,
    sort_by: orderState.sortBy,
    sort_order: orderState.sortOrder,
    start: orderState.startDate,
    end: orderState.endDate,
    search: orderState.search,
    date: orderState?.filter?.date,
    item_type: orderState.filter.item_type,
    shop: orderState.filter.shop,
    status: orderState.filter.status,
  });

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = ordersList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [ordersList]);
  useEffect(() => {
    if (orderState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [orderState.clearSelection]);

  useEffect(() => {
    if (orderState?.selectedOrder && orderState?.isEdit) {
      fillOrderData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderState?.isEdit, orderState?.showAddOrderModal]);

  const handleCloseAddOrderModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddOrderModal = false;
        state.isEdit = false;
      })
    );
  };

  const handleAddOrderClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddOrderModal = true;
        state.isEdit = false;
        state.orderData = [];
      })
    );
    dispatch(
      updateOrderData((state) => {
        state.currentFormData = {};
        state.activeTab = "Collection";
        state.isEditProfile = false;
        state.completedTabs = {
          ...state.completedTabs,
          Collection: false,
          Item: false,
          Service: false,
          Engraving: false,
        };
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Collection: 0,
          Item: 0,
          Service: 0,
          Engraving: 0,
        };
      })
    );
  };

  const fillOrderData = () => {
    getOrderData(orderState?.selectedOrder).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => (state.orderData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong to fetch data");
      }
    });
  };

  const handleEditClick = (e) => {
    dispatch(updateOrderData((state) => (state.activeTab = "Collection")));
    dispatch(
      updateConfig((state) => {
        state.showAddOrderModal = true;
        state.selectedOrder = e?.[0];
        state.isEdit = true;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      service: (feild, data) => (
        <ul>
          {data[feild]?.map(({ name }, index) => (
            <li key={index}>{name}</li>
          ))}
        </ul>
      ),
      priority: (_, data) =>
        data?.priority === "Urgent" ? (
          <span className={"pro-badge badge-danger-outline pro-d-inline-block"}>
            {"Urgent"}
          </span>
        ) : data?.priority === "Normal" ? (
          <span className={"outline-primary pro-badge pro-d-inline-block"}>
            {"Normal"}
          </span>
        ) : (
          <span className={"outline-warning pro-badge pro-d-inline-block"}>
            {data?.work_status}
          </span>
        ),
      work_status: (_, data) =>
        data?.work_status === "Rejected" ? (
          <span className={"pro-badge badge-danger-outline pro-d-inline-block"}>
            {"Rejected"}
          </span>
        ) : data?.work_status === "Marked" ? (
          <span className={"outline-primary pro-badge pro-d-inline-block"}>
            {"Marked"}
          </span>
        ) : data?.work_status === "Completed" ? (
          <span className={"outline-success pro-badge pro-d-inline-block"}>
            {"Completed"}
          </span>
        ) : data?.work_status === "Approved" ? (
          <span className={"outline-success pro-badge pro-d-inline-block"}>
            {"Approved"}
          </span>
        ) : data?.work_status === "Pending" ? (
          <span className={"outline-warning pro-badge pro-d-inline-block"}>
            {"Pending"}
          </span>
        ) : (
          <span className={"outline pro-badge pro-d-inline-block"}>
            {data?.work_status}
          </span>
        ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedOrder = e?.[0];
      })
    );
  };

  const handleDelete = () => {
    let data = { stock_receipt_id: orderState?.selectedOrder };
    deleteOrder(data).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Deleted successfully");
        handleDeleteModal();
        refetch();
      } else {
        toast.error("Something went wrong");
        handleDeleteModal();
      }
    });
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handleEditColumnsClick = (label) => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleSort = (label) => {
    if (orderState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = orderState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handlePrintClick = (id) => {
    const printId = id?.[0];

    // Fetch print data and trigger print on success
    getPrintData(printId).then((response) => {
      if (response?.data?.status_code === 200) {
        // Dispatch the data to Redux
        dispatch(
          updateConfig((state) => (state.printData = response?.data?.data))
        );

        // Call the print function after the data is successfully set
        printInvoice();
      } else {
        toast.error("Something went wrong");
      }
    });

    // Function to print the invoice
    const printInvoice = () => {
      const element = stockPrintRef?.current;
      html2pdf(element, {
        margin: [5, 2, 2, 2],
        filename: `${
          "Stock Invoice" +
          "-" +
          moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
        }.pdf`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          dpi: 100,
          letterRendering: true,
          useCORS: true,
          logging: true,
          scale: 4,
          scrollY: 0,
        },
        jsPDF: { unit: "mm", format: "a5", orientation: "portrait" },
        pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
        DisablePdfCompression: true,
      })
        .from("element-to-print")
        .outputPdf() // Output PDF without saving
        .get("pdf")
        .then((pdfObj) => {
          pdfObj.autoPrint();
          window.open(pdfObj.output("bloburl"), "F");
        });
    };
  };

  const handleAddShop = () => {
    dispatch(updateConfig((state) => (state.addShopModal = true)));
  };

  const handleCloseAddShopModal = () => {
    dispatch(updateConfig((state) => (state.addShopModal = false)));
  };

  const handleFilter = () => {
    dispatch(updateConfig((state) => (state.showFilter = true)));
  };

  const handleFilterClose = () => {
    dispatch(updateConfig((state) => (state.showFilter = false)));
  };

  const handleDateChange = (date) => {
    const startDateUTC = new Date(date.startDate);
    const endDateUTC = new Date(date.endDate);
    const timeZoneOffset = 5.5 * 60 * 60 * 1000;
    const startDateIST = new Date(startDateUTC.getTime() + timeZoneOffset);
    const endDateIST = new Date(endDateUTC.getTime() + timeZoneOffset);
    const formatDate = (date) => {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    const startDateFormatted = formatDate(startDateIST);
    const endDateFormatted = formatDate(endDateIST);
    const dateArray = [startDateFormatted, endDateFormatted];
    dispatch(updateConfig((state) => (state.filter.date = dateArray)));
  };

  return {
    date,
    ordersList: ordersList?.data,
    isFetching,
    isLoading,
    orderState,
    tableFields,
    paginationOptions,
    showEditModal,
    stockPrintRef,
    refetch,
    handleDateChange,
    handlePrintClick,
    handleFilter,
    handleSort,
    handleAddShop,
    updateOrderFields,
    handleSearch,
    handleAddOrderClick,
    handleClearClick,
    handlePagination,
    handlePageSize,
    handleCloseAddShopModal,
    handleEditClick,
    handleCloseAddOrderModal,
    handleFilterClose,
    handleEditColumnsClick,
    getRow,
    handleDelete,
    handleDeleteModal,
  };
};

export default useOrder;
