import { getAxiosInstance } from "../../../api";

export const getEstimate = async (id) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(
      `/back-office-billing/print-estimate?estimate_id=${id}`
    );
    return res;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getRegenerateEstimate = async (id) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(
      `/back-office-billing/estimate-regenerate?estimate_id=${id}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getInvoice = async (id) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(
      `/back-office-billing/print-invoice?invoice_id=${id}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getReceipt = async (id) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(
      `/back-office-billing/payment-receipt?invoice_id=${id}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const updateInvoice = async (body) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/back-office-billing/invoice-update`, body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getEditedData = async (id, page, per_page) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(
      `/back-office-billing/invoice-details?invoice_id=${id}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getEstimateInvoice = async (body) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/back-office-billing/generate-invoice`, body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const getEstimateBill = async (body) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/back-office-billing/supporting-doc`, body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};
