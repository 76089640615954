import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useRecheckDetails from "./useRecheckDetails";
import CreatableSelect from 'react-select/creatable';

const RecheckDetails = ({ refetch }) => {
  const { formik, shopObj, shopData, getFieldError, handleCancel, searchAgent, fetchCollectionAgents, formattedCollectionAgentList,
    setAddress,
    setPhoneNo
   } =
    useRecheckDetails({
      refetch,
    });
  return (
    <>
      <div>
        <div className="input-wrap pro-mb-4">
          <label
            htmlFor="shop_id"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Shop
          </label>
          <Select
            id="shop_id"
            name="shop_id"
            placeholder={"Select"}
            className={`pro-input lg  ${getFieldError("shop_id") && " error"}`}
            classNamePrefix="pro-input"
            options={shopData}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?._id}
            value={shopObj}
            onBlur={formik?.handleBlur("shop_id")}
            onChange={(value) =>
              formik?.setFieldValue("shop_id", value?._id || null)
            }
            menuPlacement="auto"
            isClearable
          />
          {getFieldError("shop_id") && (
            <span className="error-text">{getFieldError("shop_id")}</span>
          )}
        </div>
        <Input
        label={"Phone Number"}
        type="text"
        id="delivery_person_number"
        name="delivery_person_number"
        className={`pro-input lg ${getFieldError("delivery_person_number") && " error"}`}
        {...formik.getFieldProps("delivery_person_number")}
        error={getFieldError("delivery_person_number")}
        errorMessage={getFieldError("delivery_person_number")}
      />

<div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
        Collection Agent
        </label>
        <CreatableSelect
          id="collection_agent"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("collection_agent") && " error"}`}
          classNamePrefix="pro-input"
          options={formattedCollectionAgentList ?? []}
          onChange={(options) =>
            // formik.setFieldValue("collection_agent", value?._id || "")
            searchAgent(options)
          }
          onInputChange={(inputValue) => {
            // Call your API with the search term
            fetchCollectionAgents(inputValue);
          }}
      
          getOptionLabel={(options) => options?.label}
          getOptionValue={(options) => options?.value}
          onBlur={formik?.handleBlur("collection_agent")}
           value={formik?.values?.collection_agent}
          menuPlacement="auto"
        />
        {getFieldError("collection_agent") && (
          <span className="error-text">{getFieldError("collection_agent")}</span>
        )}
      </div>
        {/* <Input
          label={"Collection Agent"}
          type="text"
          id="delivery_person"
          name="delivery_person"
          className={`pro-input lg ${
            getFieldError("delivery_person") && "error"
          }`}
          {...formik.getFieldProps("delivery_person")}
          error={getFieldError("delivery_person")}
          errorMessage={getFieldError("delivery_person")}
        /> */}
        
        <div className="col-12 pro-mb-4">
          <div className="input-wrap">
            <label
              htmlFor="delivery_person_address"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Address
            </label>
            <textarea
              id="delivery_person_address"
              rows={3}
              name="delivery_person_address"
              className={`pro-input lg ${
                formik.errors.delivery_person_address &&
                formik.touched.delivery_person_address &&
                " error"
              }`}
              value={formik?.values?.delivery_person_address}
              onBlur={formik?.handleBlur("delivery_person_address")}
              onChange={(e) =>
                // formik.setFieldValue("delivery_person_address", e.target.value)
                setAddress(e)
              }
            ></textarea>
            {formik.touched.delivery_person_address &&
              formik.errors.delivery_person_address && (
                <span className="error-text">
                  {formik.errors.delivery_person_address}
                </span>
              )}
          </div>
        </div>
        <Input
          label={"Remark"}
          type="text"
          id="remark"
          name="remark"
          className={`pro-input lg ${
            getFieldError("remark") && "error"
          }`}
          {...formik.getFieldProps("remark")}
          error={getFieldError("remark")}
          errorMessage={getFieldError("remark")}
        />
        <div className="input-wrap pro-mb-4 col-12">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Due Date
          </label>
          <input
            className={`pro-input lg`}
            type="date"
            name="due_date"
            placeholder="Date of Birth"
            onChange={formik.handleChange}
            value={formik.values.due_date}
            min={new Date().toISOString().split("T")[0]}
          />
        </div>
        <div className="input-wrap pro-mb-4 col-12">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Due Time
          </label>
          <input
            className={`pro-input lg`}
            type="time"
            name="due_time"
            placeholder="Date of Birth"
            onChange={formik.handleChange}
            value={formik.values.due_time}
          />
        </div>
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik?.handleSubmit}
        >
          Submit
        </Button>
      </div>
    </>
  );
};

export default RecheckDetails;
