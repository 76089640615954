import { getAxiosInstance } from "../../api";

export const getEstimateList = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(
      `/back-office-billing/generate-estimate`,
      params
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getSubmitList = async (body) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/back-office-billing/certificate-list`, body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const updateInvoiceHistory = async (body) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/back-office-billing/payment`, body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const paymentModes = async (body) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(`/back-office-billing/payment-mode`, body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const getSupportingDoc = async (id) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(
      `/back-office-billing/invoice-supporting-doc?invoice_id=${id}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const generateBulkEstimate = async (body) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/back-office-billing/bulk-estimate`, body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getSpecialList = async (body) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(
      `/back-office-billing/stock-services-list`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const checkGenerateInvoice = async (body) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(
      `/back-office-billing/check-generate-invoice`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
