import React from "react";
import Select from "react-select";
import useMultiGliPrint from "./useMultiGliprint";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const MultiGliPrint = () => {
  const {
    gliItems,
    formik,
    uniqueItemTypes,
    handleItemChange,
    handleCancel,
    handleChange,
  } = useMultiGliPrint();

  return (
    <div className="col pro-p-4 ">
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Item Type
        </label>
        <Select
          id="item_type"
          placeholder={"Select"}
          className={`pro-input lg ${
            formik?.errors?.gli && formik?.touched?.gli && "error"
          } `}
          classNamePrefix="pro-input"
          isMulti={false}
          name="item_type"
          options={uniqueItemTypes}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.item_type}
          onChange={handleItemChange}
          menuPlacement="auto"
          onBlur={formik?.handleBlur("item_type")}
        />
        {formik?.errors?.item_type && formik?.touched?.item_type && (
          <span className="error-text">{formik.errors?.item_type}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          GLI
        </label>
        <Select
          id="gli"
          placeholder={"Select"}
          className={`pro-input lg ${
            formik?.errors?.gli && formik?.touched?.gli && "error"
          } `}
          classNamePrefix="pro-input"
          isMulti={true}
          name="gli"
          options={gliItems}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.gli_number}
          value={formik?.values?.gli}
          onChange={handleChange}
          menuPlacement="auto"
          onBlur={formik?.handleBlur("gli")}
        />
        {formik?.errors?.gli && formik?.touched?.gli && (
          <span className="error-text">{formik.errors?.gli}</span>
        )}
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
          disabled={formik?.isSubmitting}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default MultiGliPrint;
