import {
  HeadingGroup,
  ModalLayout,
  NavGroup,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Style from "../report.module.scss";
import useDWSRReport from "./useDWSRReport";
import { Link } from "react-router-dom";
import Select from "react-select";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";

const DWSRReport = () => {
  const {
    tabNavs,
    formik,
    date,
    globalState,
    reportsData,
    isFetching,
    isLoading,
    paginationOptions,
    handleEditColumnsClick,
    handleClick,
    handlePageSize,
    handlePagination,
    handleSort,
    refetch,
    getRow,
    handleDateChange,
    updateReportFields,
    reportsSummaryData,
    activeTab
  } = useDWSRReport();
  return (
    <div>
      <HeadingGroup title={"DWSR Report"} className={`pro-mb-4`} />
      <div className="col-12 pro-pt-3 pro-pb-1 ">
        <SearchFilters
          onDateChange={handleDateChange}
          showDateRange={true}
          initialDateRange={date}
          searchable={false}
          showActions={true}
          handleActionClick={handleEditColumnsClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          filterChildren={
            <div className="col">
              <div className="pro-d-flex pro-items-center pro-justify-end">
                <div className="col-4">
                  <div className="input-wrap pro-mb-0">
                    <Select
                      id="branch"
                      placeholder={"Select Branch"}
                      className={"pro-input lg"}
                      classNamePrefix="pro-input"
                      name="branch"
                      options={globalState?.branchList?.branch_list}
                      getOptionValue={(option) => option?._id}
                      getOptionLabel={(option) => option?.branch_name}
                      value={formik?.values?.branch}
                      onChange={(value) =>
                        formik.setFieldValue("branch", value)
                      }
                      menuPlacement="auto"
                    />
                  </div>
                </div>
                <button
                  className={"pro-btn pro-btn-primary lg pro-ms-3 items-center"}
                  onClick={handleClick}
                >
                  <span class="material-symbols-outlined x2">download</span>
                  <span>Export</span>
                </button>
              </div>
            </div>
          }
        />
        <div className={`${Style.main_container}`}>
          <NavGroup navigations={tabNavs} type={"type-2"} Link={Link} />
        </div>
        {activeTab !== "summary" && (
          <div className="col-auto pro-pt-1 pro-pb-6 flex-fill pro-d-flex pro-flex-column">
          <Table
            multiSelect={true}
            data={reportsData?.data?.data || []}
            uniqueID={"id"}
            fields={reportsData?.data?.fields || []}
            showCheckBox={false}
            SortIcon={<FaSort />}
            getRow={getRow}
            perpage={10}
            assignText={"Genarate Estimate"}
            assignIcon={
              <span className="material-symbols-outlined">print</span>
            }
            assignable={false}
            editable={false}
            deletable={false}
            loading={isLoading}
            fetching={isFetching}
            handleSort={handleSort}
          />
          {reportsData?.data?.data?.length > 0 && (
            <Pagination
              currentPage={reportsData?.data?.current_page}
              totalPageCount={Math.ceil(
                reportsData?.data?.total_count / reportsData?.data?.per_page
              )}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
          <ModalLayout
            show={globalState?.showEditModal}
            handleClose={handleEditColumnsClick}
          >
            <div className="pro-m-5">
              <OrderColumn
                title={"Choose which columns you see"}
                refetchData={refetch}
                tableFields={reportsData?.data?.fields ?? {}}
                moduleId={reportsData?.data?.module_id ?? null}
                updateData={updateReportFields}
              />
            </div>
          </ModalLayout>
        </div>
        )}
        {activeTab === "summary" && (
          <div className="col-auto pro-pt-1 pro-pb-6 flex-fill pro-d-flex pro-flex-column">
            <div className="row gy-5 gx-4">
            <div className="col-lg-12">
                <p className="pro-ttl h6 pro-mb-0">{reportsSummaryData?.data?.summery?.date}</p>
                <Table
                  multiSelect={false}
                  data={reportsSummaryData?.data?.summery?.table_data?.data || []}
                  uniqueID={"id"}
                  fields={reportsSummaryData?.data?.summery?.table_data?.fields || []}
                  showCheckBox={false}
                  SortIcon={<FaSort />}
                  getRow={getRow}
                  perpage={10}
                  loading={isLoading}
                  fetching={isFetching}
                  handleSort={handleSort}
                />    
              </div>
              <div className={`col-lg-6`}>
              <p className="pro-ttl h6 pro-mb-0">{reportsSummaryData?.data?.current_day?.date}</p>
                <Table
                  multiSelect={false}
                  data={reportsSummaryData?.data?.current_day?.table_data?.data || []}
                  uniqueID={"id"}
                  fields={reportsSummaryData?.data?.current_day?.table_data?.fields || []}
                  showCheckBox={false}
                  SortIcon={<FaSort />}
                  getRow={getRow}
                  perpage={10}
                  loading={isLoading}
                  fetching={isFetching}
                  handleSort={handleSort}
                />
              </div>
              <div className={`col-lg-6`}>
              <p className="pro-ttl h6 pro-mb-0">{reportsSummaryData?.data?.day_before_current_day?.date}</p>
              <Table
                multiSelect={false}
                data={reportsSummaryData?.data?.day_before_current_day?.table_data?.data || []}
                uniqueID={"id"}
                fields={reportsSummaryData?.data?.day_before_current_day?.table_data?.fields || []}
                showCheckBox={false}
                SortIcon={<FaSort />}
                getRow={getRow}
                perpage={10}
                loading={isLoading}
                fetching={isFetching}
                handleSort={handleSort}
              />
              </div>
              <div className="col-lg-6">
              <p className="pro-ttl h6 pro-mb-0">{reportsSummaryData?.data?.other?.date}</p>
          <Table
            multiSelect={false}
            data={reportsSummaryData?.data?.other?.table_data?.data || []}
            uniqueID={"id"}
            fields={reportsSummaryData?.data?.other?.table_data?.fields || []}
            showCheckBox={false}
            SortIcon={<FaSort />}
            getRow={getRow}
            perpage={10}
            loading={isLoading}
            fetching={isFetching}
            handleSort={handleSort}
          />
          </div>
          <div className="col-lg-6">
          <p className="pro-ttl h6 pro-mb-0">{reportsSummaryData?.data?.total?.date}</p>
            <Table
              multiSelect={false}
              data={reportsSummaryData?.data?.total?.table_data?.data || []}
              uniqueID={"id"}
              fields={reportsSummaryData?.data?.total?.table_data?.fields || []}
              showCheckBox={false}
              SortIcon={<FaSort />}
              getRow={getRow}
              perpage={10}
              loading={isLoading}
              fetching={isFetching}
              handleSort={handleSort}
            />
          </div>
            </div>
        </div>
        )}
        
      </div>
    </div>
  );
};

export default DWSRReport;
