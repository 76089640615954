import { subDays } from "date-fns";
import { useFormik } from "formik";
import { useState } from "react";
import {
  useGetReportBasicDataQuery,
  useGetReportTableDataQuery,
} from "../../../store/queries/Reports";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/Reports/reportsSlice";
import { downloadPercentageReport } from "../api";
import moment from "moment";
import { toast } from "react-toastify";

const usePercentageReport = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();
  const { data: basicData } = useGetReportBasicDataQuery();
  const reportState = useSelector((state) => state.reports);

  // eslint-disable-next-line no-unused-vars
  const [date, setDate] = useState({
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
    key: "selection",
  });

  const startDate = new Date(date.startDate).toISOString().split("T")[0];
  const endDate = new Date(date.endDate).toISOString().split("T")[0];
  const initialFormattedDate = [startDate, endDate];
  const [formattedDate, setFormattedDate] = useState(initialFormattedDate);

  const formik = useFormik({
    initialValues: {
      date: "",
      type: "",
      shop_supplier: "",
    },
  });

  const handleDateChange = (date) => {
    const startDateUTC = new Date(date.startDate);
    const endDateUTC = new Date(date.endDate);
    const timeZoneOffset = 5.5 * 60 * 60 * 1000;
    const startDateIST = new Date(startDateUTC.getTime() + timeZoneOffset);
    const endDateIST = new Date(endDateUTC.getTime() + timeZoneOffset);

    const formatDate = (date) => {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    const startDateFormatted = formatDate(startDateIST);
    const endDateFormatted = formatDate(endDateIST);
    const dateArray = [startDateFormatted, endDateFormatted];
    setFormattedDate(dateArray);
  };

  const handleShopChange = (value) => {
    formik.setFieldValue("shop_supplier", value);
  };

  const {
    data: tableData,
    isFetching,
    isLoading,
  } = useGetReportTableDataQuery(
    {
      page_size: reportState.percentageReport?.currentPageSize,
      page: reportState.percentageReport?.currentPage,
      sort_by: reportState.percentageReport?.sortBy,
      sort_order: reportState.percentageReport?.sortOrder,
      shop_supplier_id: formik?.values?.shop_supplier?._id,
      shop_or_supplier_type: formik?.values?.type?.id,
      date: formattedDate,
      branch_ids: reportState?.percentageReport?.currentBranchFilter,
    },
    {
      skip: !(
        formik?.values?.shop_supplier?._id &&
        formik?.values?.type?.id &&
        formattedDate
      ),
    }
  );

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.percentageReport.currentPageSize = page_size;
        state.percentageReport.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.percentageReport.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (reportState.percentageReport.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.percentageReport.currentPage = 1;
          state.percentageReport.sortOrder =
            reportState.percentageReport.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.percentageReport.currentPage = 1;
          state.percentageReport.sortBy = label;
          state.percentageReport.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleDownloadClick = () => {
    let data = {
      shop_supplier_id: formik?.values?.shop_supplier?._id,
      shop_or_supplier_type: formik?.values?.type?.id,
      branch_ids: reportState?.percentageReport?.currentBranchFilter,
      date: formattedDate,
    };
    downloadPercentageReport(data).then((response) => {
      if (response?.status === 200) {
        let dataType = response?.data?.type;
        let binaryData = [];
        binaryData.push(response?.data);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute(
          "download",
          `${moment(Date.now()).format("MM_DD_YYYY_")}${`excel`}.xlsx`
        );
        document.body.appendChild(downloadLink);
        downloadLink.click();
      } else if (response?.status === 403) {
        toast.warning("Invalid Data");
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const handleBranchFilter = (ids) => {
    dispatch(
      updateConfig((state) => {
        state.percentageReport.currentPage = 1;
        state.percentageReport.currentBranchFilter = ids;
      })
    );
  };

  return {
    date,
    formik,
    basicData,
    tableData,
    isFetching,
    isLoading,
    reportState,
    paginationOptions,
    getRow,
    handleSort,
    handlePageSize,
    handlePagination,
    handleDateChange,
    handleShopChange,
    handleDownloadClick,
    handleBranchFilter,
  };
};

export default usePercentageReport;
